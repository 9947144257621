import {YYYY_MM_DD} from "@/utils/constants";

export default {
    methods: {
        dateFormat(date) {
            if (date) {
                return this.$moment(date).format(YYYY_MM_DD)
            }
            return ''
        },
        timezFormat(date) {
            if (date) {
                return this.$moment(date).format('HH:mm')
            }
            return ''
        },
        reservedFormat(reserved_at) {
            if (reserved_at) {
                return this.$moment(reserved_at).format(YYYY_MM_DD + '(ddd) HH:mm')
            }
            return ''
        },
        dateFormatWithDay(date) {
            if (date) {
                return this.$moment(date).format(YYYY_MM_DD + '(ddd)')
            }
            return ''
        },
    }
}
