export default {
    data() {
        return {
            locationsSelected: []
        }
    },
    computed: {
        locationSelected() {
            return this.$store.getters.loginInfo || {}
        }
    },
}
