export default[
    {
        path: 'user',
        name: 'user',
        meta: {
            title: {
                i18n: 'title.user.index'
            },
            require_auth: true
        },
        component: () => import('../../views/user/List')
    },
    {
        path: 'user/create',
        name: 'user-create',
        meta: {
            title: {
                i18n: 'title.user.create'
            },
            require_auth: true
        },
        component: () => import('../../views/user/List')
    },
    {
        path: 'user/:id',
        name: 'user-detail',
        meta: {
            title: {
                i18n: 'title.user.detail'
            },
            require_auth: true
        },
        component: () => import('../../views/user/List')
    },
    {
        path: 'user/:id/edit',
        name: 'user-edit',
        meta: {
            title: {
                i18n: 'title.user.edit'
            },
            require_auth: true
        },
        component: () => import('../../views/user/List')
    }
]
