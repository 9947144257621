export default {
    methods: {
        getStatusColor(status) {
            if (status === '来店前') {
                return '#F29B76'
            }
            if (status === '来店中') {
                return '#EA5532'
            }
            if (status === '精算済') {
                return '#530000'
            }
            return '#ffffff'
        }
    }
}
