import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import loading from './loading'
import search from './search'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        loading,
        search
    }
})

export default store
