import {
  SET_LOADING
} from './actions'

import {
  SET_LOADING_API
} from './mutations'

/**
 * state
 */
const state = {
  isLoading: false
}

/**
 * actions
 */
const actions = {
  [SET_LOADING]({ commit }, payload) {
    commit(SET_LOADING_API, !!payload)
  }
}

/**
 * mutations
 */
const mutations = {
  [SET_LOADING_API]: (state, loading) => {
    state.isLoading = loading
  }
}

/**
 * getters
 */
const getters = {
  loadingApi: (state) => state.isLoading
}

export default {
  state,
  actions,
  mutations,
  getters
}
