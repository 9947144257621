// import authService from "@/services/auth";
import Vue from "vue"

import {
  ACTION_BACK_PAGE,
  ACTION_LOGIN,
  ACTION_LOGOUT,
  SET_LOCATIONS
} from './actions'

import {
  BACK_PAGE,
  LOGIN,
  LOGOUT,
  SET_LIST_LOCATION
} from './mutations'

const state = {
  login_info: null,
  backPage: "",
  locations: [],
}

const getters = {
  loginInfo(state) {
    return state.login_info || JSON.parse(localStorage.getItem('login_info'));
  },

  isAuthenticated(state) {
    return !!state.login_info
  },
  locations: (state) => state.locations.length ? state.locations : JSON.parse(localStorage.getItem('locations')),
  backPage: (state) => state.backPage,
}

const actions = {
  [ACTION_LOGIN](context, payload) {
    // const {data} = await authService.login(payload);
    // localStorage.setItem('userInfo', JSON.stringify(data.user))
    // localStorage.setItem('accessToken', data.access_token)
    // context.commit(LOGIN, data);
    payload.expired = (new Date()).getTime() + (Vue.prototype.$config.timeOut * 1000 * 60)
    localStorage.setItem('login_info', JSON.stringify(payload));
    context.commit(LOGIN, payload);
  },
  [SET_LOCATIONS]({commit}, locations) {
    commit(SET_LIST_LOCATION, locations)
  },

  async [ACTION_LOGOUT](context) {
    // await authService.logout();
    // context.commit(LOGOUT);
    // localStorage.removeItem('login_info')
    localStorage.removeItem('login_info');
    context.commit(LOGOUT);
  },

  async [ACTION_BACK_PAGE](context, payload) {
    context.commit(BACK_PAGE, payload)
  },
}

const mutations = {
  [LOGIN](state, login_info) {
    state.login_info = login_info;
  },

  [LOGOUT](state) {
    Object.assign(state, {
      login_info: null
    });
  },

  [BACK_PAGE](state, backPage) {
    state.backPage = backPage
  },
  [SET_LIST_LOCATION]: (state, locations) => {
    state.locations = locations
  },
}

export default {
  state,
  actions,
  mutations,
  getters
}
