import user from './user'
import home from './home'
import form from './form'
import search from './search'
import customer from './customer'

export default {
    user,
    home,
    form,
    search,
    customer,
}
