<template>
  <div class="loading">
    <img class="icon-loading" src="@/assets/images/icons/ic_loading.svg" alt="" width="120">
  </div>
</template>

<script>
export default {
  name: "loading"
}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  background-color: $light-grey;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  top: 0;
  opacity: 0.4;

  .icon-loading {
    margin: auto;
    display: block;
    margin-top: calc(50vh - 60px);
  }
}
</style>
