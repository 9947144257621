import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    maxToasts: 1,
    newestOnTop: true,
    timeout: 3000,
    closeOnClick: true,
    closeButton: false,
};

Vue.use(Toast, options);
