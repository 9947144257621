import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/plugins/i18n'
import modules from './modules'
import {flatten} from 'lodash'
import store from "@/store"
import "@/plugins/notify"

Vue.use(Router)
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/home',
            component: () => import('../components/layout/Auth'),
            beforeEnter: guardRoute,
            meta: {
                title: {
                    i18n: 'title.home'
                },
                require_auth: true
            },
            children: [
                ...flatten(Object.values(modules)),
            ]
        },
        {
            path: '/login',
            component: () => import('../views/auth/Login'),
            name: 'login',
            meta: {
                title: {
                    i18n: 'login.title'
                },
                require_auth: false
            },
        },
        {
            path: '/*',
            meta: {
                title: {
                    i18n: 'title.not-found'
                }
            },
            name: 'notfound',
            component: () => import('../views/error/404')
        }
    ],
    scrollBehavior: scrollTop,
})

router.beforeEach((to, from, next) => {
    if(to.meta.title) {
        document.title = itemTitle(to.meta)
    }

    if (to.name === 'login' && isAuthenticated()) {
        next('/')
    } else {
        next()
    }
})

function guardRoute(to, from, next) {
    if (isAuthenticated()) {
        next()
    } else {
        next({name: 'login'})
    }
}

function scrollTop(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
}

function isAuthenticated() {
    return store.getters.isAuthenticated || !!localStorage.getItem('login_info')
}

function itemTitle(item) {
    if (typeof item.title === 'object') {
        return i18n.tc(item.title.i18n)
    } else {
        return item.title
    }
}

export default router
