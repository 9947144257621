export default[
    {
        path: 'form',
        name: 'form',
        meta: {
            title: {
                i18n: 'title.form.index'
            },
            require_auth: true
        },
        component: () => import('../../views/form/Form')
    },
]
