import App from './App';
import Vue from 'vue';
import './plugins/lodash'
import './plugins/notify'
import i18n from './plugins/i18n';
import router from './routers';
import store from './store';
import Vuelidate from 'vuelidate'
import moment from 'moment'
import Mixins from './mixins';
import Components from './components/commons';
import Directives from './directives'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueLazyload from 'vue-lazyload'

moment.locale('ja')
Vue.prototype.$moment = moment

Vue.use(VueLazyload, {
  error: require('@/assets/images/icons/ic_user_white.svg'),
  loading: require('@/assets/images/icons/ic_loading.svg'),
  attempt: 1
})
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(moment)
Mixins(Vue)
Directives(Vue)
Components(Vue)

Vue.config.productionTip = false;

fetch('/_config_.json')
    .then(response => response.json())
    .then(config => {
      Vue.prototype.$config = config
      new Vue({
        el: '#app',
        i18n,
        store,
        render: h => h(App),
        router
      })
    })
