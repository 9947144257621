export default[
    {
        path: 'customer-detail/:customer_no/:customer_id/:customer_history_id/:target_date_from/:target_date_to',
        name: 'customer-detail',
        meta: {
            title: {
                i18n: 'title.customer_detail'
            },
            require_auth: true
        },
        component: () => import('../../views/customer/Detail')
    },
    {
        path: 'customer-reserved/:customer_no/:customer_id/:customer_history_id/:target_date_from/:target_date_to',
        name: 'customer-reserved',
        meta: {
            title: {
                i18n: 'title.customer_reserve'
            },
            require_auth: true
        },
        component: () => import('../../views/customer/Reserved')
    },
]
