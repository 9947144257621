import datetime from './datetime'
import images from './images'
import color from './color'
import common from './common'

export default (Vue) => {
    Vue.mixin(datetime)
    Vue.mixin(images)
    Vue.mixin(color)
    Vue.mixin(common)
}
