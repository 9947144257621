export default[
    {
        path: 'home',
        name: 'home',
        meta: {
            title: {
                i18n: 'title.home'
            },
            require_auth: true
        },
        component: () => import('../../views/home/Home')
    },
]
