export default {
    methods: {
        imgStyle(img) {
            return {
                'background-image': img ? 'url(' + img + ')' : 'url(' + require('@/assets/images/icons/ic_user_white.svg') + ')',
                'width': img ? '100%' : '65px',
                'height': img ? '100%' : '65px',
                'background-position': 'center'
            }
        },
        imgBase64Style(img) {
            return {
                'background-image': img ? 'url("data:image/png;base64,' + img + '")' : 'url(' + require('@/assets/images/icons/ic_user_white.svg') + ')',
                'width': img ? '100%' : '65px',
                'height': img ? '100%' : '65px',
                'background-position': 'center'
            }
        },
        getImage(coalesce) {
            if (coalesce) {
                return this.$config.homeUrl + coalesce
            }
            return ''
        }
    }
}
