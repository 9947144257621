import {
  ACTION_SET_SEARCH_INFO
} from './actions'

import {
  SET_SEARCH_INFO
} from './mutations'

/**
 * state
 */
const state = {
  searchInfo: null
}

/**
 * actions
 */
const actions = {
  [ACTION_SET_SEARCH_INFO]({ commit }, payload) {
    commit(SET_SEARCH_INFO, payload)
  }
}

/**
 * mutations
 */
const mutations = {
  [SET_SEARCH_INFO]: (state, searchInfo) => {
    state.searchInfo = searchInfo
  }
}

/**
 * getters
 */
const getters = {
  searchInfo: (state) => state.searchInfo
}

export default {
  state,
  actions,
  mutations,
  getters
}
